import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import * as styles from './navigation.scss'

const Navigation = ({ modifiers }) => (
  <ul className="c-navigation" className={"c-navigation" + (modifiers ? ' c-navigation--' + modifiers : '')}>
    <li className="c-navigation__item">
      <a href="https://rates.pilot.money/" target="_blank" className="c-navigation__button-wrapper">Remittance Rates Database &rarr;</a>
    </li>
    <li className="c-navigation__item">
      <a href="mailto:hello@pilot.money" className="c-navigation__button-wrapper">Contact Us</a>
    </li>
  </ul>
)

export default Navigation
