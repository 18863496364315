import PropTypes from 'prop-types'
import React from 'react'
import Copyright from '../copyright/copyright'

import * as styles from './footer.scss'

const Footer = ({ siteTitle, modifier }) => (
  <div className={'o-site__footer c-pageFooter ' + (modifier ? 'c-pageFooter--' + modifier : '')}>
    <div className="o-block">
      <div className="c-pageFooter__divider"></div>
      <div className="c-pageFooter__inner">
        <div className="c-pageFooter__column c-pageFooter__column--left">
          <div className="o-inner">
            <ul className="c-navigation c-navigation--footer">
              <li className="c-navigation__item">
                <a
                  href="mailto:hello@pilot.money"
                  target="_blank"
                  className="c-navigation__button-wrapper"
                  id="contactButton"
                >
                  Contact us
                </a>
              </li>
              <li className="c-navigation__item">
                <a className="c-navigation__button-wrapper" href="https://www.iubenda.com/privacy-policy/14846343/legal" target="_blank">Privacy Policy</a>
              </li>
              <li className="c-navigation__item">
                <a className="c-navigation__button-wrapper" href="https://www.iubenda.com/privacy-policy/66099393" target="_blank">Chatbot Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="c-pageFooter__column c-pageFooter__column--right">
          <div className="o-inner">
            <Copyright siteTitle={`Pilot`} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
