import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Navigation from '../navigation/navigation'
import * as styles from './header.scss'

const Header = ({ siteTitle, modifiers }) => {

  const [isExpanded, toggleExpansion] = useState(false)

  let modifierClasses = '';
  if (modifiers) {
    for (let modifier of modifiers) {
      let modifierClass = ' c-pageHeader--' + modifier
      modifierClasses = modifierClasses + modifierClass
    }
  }

  return (
    <header className={`c-pageHeader${(modifiers ? modifierClasses : '')}${ isExpanded ? ` is-expanded` : ` is-closed` }`}>
      <div className="o-block">
        <div className="c-pageHeader__left">
          <div className="o-inner">
            <h1 className="o-brand"><Link to="/" className="c-pageHeader__brand">{ siteTitle }</Link></h1>
          </div>
        </div>
        <div className="c-pageHeader__right">
          <button onClick={() => toggleExpansion(!isExpanded)} className="c-pageHeader__navigationToggle"><span aria-hidden="true" className="u-visuallyHidden">Menu</span><span className="c-pageHeader__bar"></span></button>
          <nav role="navigation" className={`c-pageHeader__navigation`}>
            <div className="o-inner">
              <Navigation modifiers={[`main`]} />
            </div>
          </nav>
        </div>
      </div>
    </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
