import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import 'reset-css'

import Header from './header/header'
import Footer from './footer/footer'
//import './layout.css'

import '../styles/main.scss'

const Layout = ({ modifiers, children }) => {

  let modifierClasses = '';
  if (modifiers) {
    for (let modifier of modifiers) {
      let modifierClass = ' o-site--' + modifier
      modifierClasses = modifierClasses + modifierClass
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className={'o-site' + (modifiers ? modifierClasses : '')}>
          <div className="o-site__inner">
            <div className="o-site__main">
              <Header siteTitle={data.site.siteMetadata.title} modifiers={modifiers} />
              {children}
            </div>
            <Footer siteTitle={data.site.siteMetadata.title} modifiers={modifiers}/>
          </div>
        </div>
      )}
    />
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
