import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import * as styles from './copyright.scss'

const Copyright = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query BuildTimeQuery {
        site {
          buildTime
        }
      }
    `}
    render={data => (
      <>
        <div className="c-copyright"><span className="c-copyright__title">&copy; {new Date().getFullYear()} Finance for All Limited</span></div>
      </>
    )}
  />
)

export default Copyright
